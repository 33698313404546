import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/fetch-api",
  "date": "2016-02-13",
  "title": "FETCH API",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "The Fetch API is a Promise-based standard for performing AJAX requests. It provides a fetch method which returns a Promise on the window object, which we can use to perform requests from remote resources."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Why Fetch API?`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`XHR`}</inlineCode>{` or the XMLHttpRequest has been around for over 10 years, and it has a nice API built around it, but many things has changed since XHR was introduced. We have HTML5, CSS3, also close to start using ES6. From jQuery Deferred, $q and Native JavaScript Promises people became familiar and like promises in JS, its's time for a new API to do AJAX-requests and interact with them.`}</p>
    <h2>{`A Quick Look at XMLHttpRequest`}</h2>
    <p>{`Let's look at how we can use XMLHttpRequest to retrieve JSON.`}</p>
    <p>{`We start by creating a `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` file and adding the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<!DOCTYPE html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width">
    <title>GitHub Info</title>
  </head>
  <body>
    <div>
     <img alt="Avatar" id="avatar" style="height:100px"/>
     <p id="name">
    </div>
  </body>
</html>
`}</code></pre>
    <p>{`The JavaScript code for our XMLHttpRequest will look as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function request(params) {
  var xhr = new XMLHttpRequest();

  xhr.open('GET', params.url, true);
  xhr.onreadystatechange = function () {
    var result = JSON.parse(xhr.responseText);

    if(xhr.status === 200) {
      document.querySelector('#avatar').src = result.avatar_url;
      document.querySelector('#name').innerText =
          result.name.split(' ')[0];
    } else {
      console.log(JSON.parse(xhr.responseText));
    }
  };
  xhr.send();
}

request({
  url: 'https://api.github.com/users/szaranger'
});
`}</code></pre>
    <p>{`Here we are using event handlers instead of Promise. These event handlers already leads into spaghetti code and mess.`}</p>
    <h2>{`Using Fetch`}</h2>
    <p>{`Let's look at how we can do the same thing with Fetch. The Ftech function is available from the global `}<inlineCode parentName="p">{`window`}</inlineCode>{` object and the first parameter is the URL. We are using the same `}<strong>{`index.html`}</strong>{` file for the next example, but for our JavaScript code, we are using fetch instead of XHR.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`fetch('https://api.github.com/users/szaranger', {
    method: 'GET'
}).then(function(response) {
  if(response.status === 200) {
    response.json().then(function(result) {
      document.querySelector('#avatar').src = result.avatar_url;
      document.querySelector('#name').innerText =
          result.name.split(' ')[0];
    });
  } else {
    console.log('Request returned ' + response.status);
  }
}).catch(function(error) {
   console.log(error)
});
`}</code></pre>
    <p>{`As you can see, fetch API uses Promises heavily to handle results. This is going to be the future of communicating with remote APIs.`}</p>
    <h2>{`Handling the JSON Response`}</h2>
    <p>{`In our second example, using fetch, the result has a `}<inlineCode parentName="p">{`json`}</inlineCode>{` method, which we call int turn to convert the response into a JSON object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`if(response.status === 200) {
  response.json().then(function(result) {
    ...
  });
}
`}</code></pre>
    <p>{`This `}<inlineCode parentName="p">{`json`}</inlineCode>{` method is a shortcut for `}<inlineCode parentName="p">{`JSON.parse(string)`}</inlineCode>{` method.`}</p>
    <Message type="info" title="Polyfill" content="There's a Polyfill available for fetch as well - [https://github.com/github/fetch](https://github.com/github/fetch)." mdxType="Message" />
    <h2>{`Response`}</h2>
    <p>{`To customize our fetch call, we can create a `}<inlineCode parentName="p">{`Request`}</inlineCode>{` object with advanced options:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var request = new Request('https://api.github.com/users/szaranger', {
    method: 'GET',
    mode: 'cors',
    redirect: 'follow',
    headers: new Headers({
        'Content-Type': 'text/json'
    })
});
`}</code></pre>
    <p>{`Now we can use this in place of the fetch call as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`fetch(request).then(function(response) {
  if(response.status === 200) {
    response.json().then(function(result) {
      document.querySelector('#avatar').src = result.avatar_url;
      document.querySelector('#name').innerText =
          result.name.split(' ')[0];
    });
  } else {
    console.log('Request returned ' + response.status);
  }
}).catch(function(error) {
   console.log(error)
});
`}</code></pre>
    <p>{`The only mandatory property is the first parameter, which is the URL for the Request instance.`}</p>
    <p>{`The response object also provides the following methods:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`arrayBuffer`}</inlineCode>{` - An ArrayBuffer will resolve the returned promise`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`blob`}</inlineCode>{` - A Blob will resolve the returned promise`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`json`}</inlineCode>{` - A JSON object will resolve the returned promise`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`text`}</inlineCode>{` - A text string will resolve the returned promise`}</li>
    </ul>
    <h3>{`JSON`}</h3>
    <p>{`If you want your results formatted in JSON - you do so with the `}<inlineCode parentName="p">{`json`}</inlineCode>{` method, which converts the raw data to a JavaScript object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`fetch(request).then(function(response) {
  if(response.status === 200) {
    response.json().then(function(result) {
      ...
    });
  }
});
`}</code></pre>
    <h3>{`Blob`}</h3>
    <p>{`Ideal for handling results like loading an image. The `}<inlineCode parentName="p">{`blob`}</inlineCode>{` method does so by taking a Response stream and reading it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`fetch('https://avatars3.githubusercontent.com/u/658820').then(
   function(response) {
     return response.blob();
   }).then(function(blob) {
     document.querySelector('#avatar').src = URL.createObjectURL(blob);
   });
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`The fetch API's support for Promises makes it the ideal choice making AJAX requests the right way.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      